const AFC = require( './controllers/animationFrameController.js' );
const ETC = require( './controllers/eventThrottleController.js' );
const IVC = require( './controllers/inViewportController.js' );
const BCC = require( './controllers/boundingClientRectController.js' );

function select( query, root ){
	
	try {
		return [...(root||document).querySelectorAll( query )];
	} catch(e){
		return [];
	}
	
}
function getScrollTop(){
	
	return (document.documentElement ? document.documentElement.scrollTop : document.body.scrollTop) || document.body.scrollTop || 0;
	
}
function setScrollTop( top ){

	(document.documentElement ? document.documentElement : document.body).scrollTop = top;
	document.body.scrollTop = top;
	
}
function scrollToTop( top, duration = null ){
	
	if( scrollToTop.isScrolling ) return;
	else scrollToTop.isScrolling = true;
	
	if( duration === null ){
		
		let pixelsPerMillisecond = 4;
		
		duration = Math.abs( getScrollTop() - top ) / pixelsPerMillisecond;
		
	}
	
	var start = getScrollTop();
	
	function scroll( d, p, t ){
		
		//var progress = clamp( p / duration );
		var progress = clamp( p / duration );
		var _progress = -Math.pow(progress - 1, 2) + 1;
		
		if( progress >= 1 ){
			
			setScrollTop( top );
			
			scrollToTop.isScrolling = false;
			
			endEventPrevention();
			
			return false;
			
		} else {
			
			var end = start + (top - start) * _progress;
			
			setScrollTop( end );
			
		}
		
	}
	function eventPrevention( event ){
		
		event.preventDefault();
		event.stopImmediatePropagation();
		
	}
	function endEventPrevention(){
		
		ETC.delete( document, 'wheel', eventPrevention );
		ETC.delete( document, 'touchstart', eventPrevention );
		ETC.delete( document, 'touchmove', eventPrevention );
		ETC.delete( document, 'touchend', eventPrevention );
		
	}
	
	ETC.add( document, 'wheel', eventPrevention );
	ETC.add( document, 'touchstart', eventPrevention );
	ETC.add( document, 'touchmove', eventPrevention );
	ETC.add( document, 'touchend', eventPrevention );
	
	AFC.add( scroll );
	
}
function clamp( value, ...between ){
	
	while( between.length < 2 ) between.push( between.length );
	
	var min = Math.min( ...between );
	var max = Math.max( ...between );
	
	return value < min ? min : (value > max ? max : value);
	
}
function getBreakPoint(){
	
	return (window.getComputedStyle( document.body, ':before' ).getPropertyValue( 'content' ) || (innerWidth < 800 ? 'mobile' : 'desktop')).replace( /[\"\']+/gi, '' );
	
}
function request( url ){
	
	return new Promise(function( resolve, reject ){
		
		var request = new XMLHttpRequest;
	
		request.addEventListener( 'readystatechange', event => {
		
			if( request.readyState === 4 && request.status == 200 ){
			
				resolve( request );
			
			} else if( request.readyState === 4 ){
				
				reject( request );
				
			}
		
		});
	
		request.open( 'GET', url );
		request.send();
	
	});
	
}

function evaluate_reflect( element ){
	
	function draw(){
	
		canvas.width = element.naturalWidth;
		canvas.height = element.naturalHeight;
		
		var { width, height } = canvas;
		var gradient = ctx.createLinearGradient( 0, height, 0, 0 );
		
		gradient.addColorStop( 0, 'black' );
		gradient.addColorStop( 1, 'transparent' );
	
		ctx.save();
	
		ctx.translate( width, height );
		ctx.rotate( Math.PI );
		ctx.drawImage( image, 0, 0, width, height );
	
		ctx.fillStyle = gradient;
		ctx.globalCompositeOperation = 'destination-in';
		ctx.fillRect( 0, 0, width, height );
		
		ctx.restore();
	
	}
	
	var wrapper = document.createElement( 'div' );
	var canvas = document.createElement( 'canvas' );
	var ctx = canvas.getContext( '2d' );
	var image = new Image;
	
	image.addEventListener( 'load', draw );
	image.src = element.src;
	
	canvas.classList.add( 'reflect--reflection' );
	
	element.parentNode.insertBefore( wrapper, element );
	
	wrapper.classList.add( 'reflect--group' );
	wrapper.appendChild( element );
	wrapper.appendChild( canvas );
	
}
function evaluate_img_svg( element ){
	
	if( element.classList.contains( 'reflect--reflection' ) ){
		
		return;
		
	}
	
	var svgPath = element.getAttribute( 'data-svg' );
		svgPath = svgPath || element.src.split( '.' ).slice( 0, -1 ).join( '.' ) + '.svg';
	
	request( svgPath ).then(request => {
		
		var prefix = 1;
		
		while( document.querySelector( `[data-localise="svg-local-${prefix}"]` ) ){
			
			prefix++;
			
		}
		
		prefix = 'svg-local-' + prefix;
		
		var tmp = document.createElement( 'div' );
		var svg = request.responseXML.querySelector( 'svg' );
		
		tmp.innerHTML = select( '[id]', svg ).reduce((html, id) => {
			
			id = id.getAttribute( 'id' );
			
			html = html.replace( `id="${id}"`, `id="${prefix}${id}"` );
			html = html.replace( `#${id}`, `#${prefix}${id}` );
			
			return html;
			
		}, request.responseText);
		
		svg = tmp.querySelector( 'svg' );
		
		svg.setAttribute( 'class', element.className );
		svg.setAttribute( 'id', element.id );
		
		element.parentNode.insertBefore( svg, element );
		element.remove();
		
	});
	
}
function evaluate_nav( element ){
	
	function resize(){
		
		shadow.style.height = element.clientHeight + 'px';
		dom.style.maxHeight = (innerHeight - element.clientHeight) + 'px';
		
	}
	function scroll(){
	
		if( top !== null && Math.abs( top - getScrollTop() ) > 40 ){
			
			element.classList.remove( 'open' );
			top = null;
			
		}
		
	}
	function click( event ){
		
		if( event.target === element ){
		
			event.preventDefault();
			
			element.classList.toggle( 'open' );
			
			if( element.classList.contains( 'open' ) ){
				
				top = getScrollTop();
				
			} else {
				
				top = null;
				
			}
		
		} else {
			
			element.classList.remove( 'open' );
			
		}
		
	}
	
	var shadow = document.createElement( 'div' );
	var dom = element.querySelector( 'div' );
	var top = null;
	
	shadow.classList.add( 'shadow' );
	
	window.addEventListener( 'resize', resize );
	window.addEventListener( 'scroll', scroll );
	
	element.addEventListener( 'click', click );
	element.parentNode.insertBefore( shadow, element );
	
	resize();
	
}
function evaluate_anchor( a ){
	
	var href = a.getAttribute( 'href' );
	var element = select( href )[ 0 ];
	
	if( element && element.classList.contains( 'popover' ) ){
		
		a.addEventListener( 'click', event => {
			
			event.preventDefault();
			
			element.classList.toggle( 'active' );
			
			if( element.classList.contains( 'active' ) ){

				[ ...element.childNodes ].forEach(node => {
				
					if( node.tagName ){
						
						node.scrollTop = 0;
						
					}
				
				})
				
			}
			
		});
		
	} else if( element ){
		
		a.addEventListener( 'click', event => {
			
			event.preventDefault();
			
			var top = element.getBoundingClientRect();
			
			scrollToTop( top + getScrollTop(), Math.abs( top ) );
			
		});
		
	} else if( href && href.indexOf( 'http' ) === 0 ){
		
		a.setAttribute( 'target', '_blank' );
		
	}
	
}
function evaluate_popover( popover ){
	
	var header = popover.querySelector( 'header' );
	
	popover.addEventListener( 'click', event => {
		
		if( event.target === popover ){
			
			popover.classList.remove( 'active' );
			
		}
		
	});
	
	[ ...popover.childNodes ].forEach(content => {
		
		if( content.tagName ){
		
			var header = content.querySelector( 'header' );
	
			content.addEventListener( 'scroll', event => {
				
				if(
					content.scrollTop > header.clientHeight 
					&& header.clientWidth + 40 >= innerWidth
				){
			
					popover.classList.add( 'past-header' );
			
				} else {
			
					popover.classList.remove( 'past-header' );
			
				}
		
			});
		
		}
	
	});
		
}

select( 'img.reflect' ).forEach( evaluate_reflect );
select( 'img[data-svg]' ).forEach( evaluate_img_svg );
select( 'nav' ).forEach( evaluate_nav );
select( 'a' ).forEach( evaluate_anchor );
select( '.popover' ).forEach( evaluate_popover );